import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormGroupControls } from '@core/types/form-group-controls';

export function PasswordMatchValidator<T>(
  controlName: keyof FormGroupControls<T>,
  matchingControlName: keyof FormGroupControls<T>
): ValidatorFn {
  return (abstractControl: AbstractControl) => {
    const control = abstractControl.get(controlName as string);
    const matchingControl = abstractControl.get(matchingControlName as string);

    if (matchingControl!.errors && !matchingControl!.errors?.['notMatch']) {
      return null;
    }

    if (control!.value !== matchingControl!.value) {
      const error = { notMatch: true };
      matchingControl!.setErrors(error);
      return error;
    } else {
      matchingControl!.setErrors(null);
      return null;
    }
  };
}
