import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { PasswordRuleErrors } from '@core/helpers/control-validators/password-rules/password-rules';
import { PasswordRuleErrorComponent } from '@features/profile/components/forms/profile-change-password-form/change-password-form/component/password-rule-error.component';
import { ValidationErrors } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-password-rule-validator',
  imports: [PasswordRuleErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="grid grid-cols-2 gap-4">
      <app-password-rule-error
        [label]="'Requires one special character'"
        [hasError]="
          errors()?.hasMissingSpecialCharacter
        "></app-password-rule-error>
      <app-password-rule-error
        [label]="'Requires one number'"
        [hasError]="errors()?.hasMissingNumber"></app-password-rule-error>
      <app-password-rule-error
        [label]="'Requires one upper letter'"
        [hasError]="errors()?.hasMissingUpperLetter"></app-password-rule-error>
      <app-password-rule-error
        [label]="'Requires >= 8 characters'"
        [hasError]="
          errors()?.hasMissingCharacterLength
        "></app-password-rule-error>
    </div>
  `,
})
export class PasswordRuleValidatorComponent {
  errors = input.required<
    PasswordRuleErrors | ValidationErrors | null | undefined
  >();
}
