import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  selector: 'app-password-rule-error',
  imports: [FontAwesomeModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex gap-2">
      @if (!hasError()) {
        <fa-icon
          [icon]="['fas', 'check']"
          class="self-center text-primary-400"></fa-icon>
        <span>{{ label() }}</span>
      } @else {
        <fa-icon
          [icon]="['fas', 'xmark']"
          class="self-center text-neutral-300"></fa-icon>
        <span class="text-neutral-300">{{ label() }}</span>
      }
    </div>
  `,
})
export class PasswordRuleErrorComponent {
  label = input.required<string>();
  hasError = input.required<boolean>();
}
